<template>
  <Page :title="title" :top-image="topImage">
    <v-row justify="center" no-gutters>
      <v-col>
        <v-timeline :dense="$vuetify.breakpoint.smAndDown" class="my-6 ml-n5 ml-md-0" :reverse="$vuetify.breakpoint.mdAndUp">
          <v-timeline-item
            v-for="(item, index) in roadmapItems"
            :key="item.label"
            :color="item.passed ? '#00FFFF' : '#FF5800'"
            small
            fill-dot
            ref="titem"
          >
            <v-card class="roadmap-card pa-md-10 pa-5 ml-n5 ml-md-0" :class="[
                item.animated ? (item.passed ? 'animated-passed' : 'animated-not-passed') : '',
                index == 0 ? 'mb-md-10' : 'my-md-10',
              ]">
              <v-card-title>
                <div
                  :style="{
                    color: item.passed ? '#00FFFF !important' : '#FF5800 !important',
                    wordBreak: 'normal',
                  }"
                  :class="itemTitleFont"
                >
                  {{ item.label }}
                </div>
              </v-card-title>
              <v-card-text>
                <div :class="item.passed ? 'roadmap-card-divider-passed' : 'roadmap-card-divider-not-passed'" class="my-3 my-md-6" />
                <div :class="itemContentFont">
                  {{ item.content }}
                </div>
              </v-card-text>
            </v-card>
          </v-timeline-item>

          <div :class="timelineOverlay" v-bind:style="glowLineStyle"></div>
        </v-timeline>
      </v-col>
    </v-row>
  </Page>
</template>

<script>
import Page from "../DarkPage.vue";

export default {
  name: "Roadmap",
  metaInfo: {
    title: "Roadmap"
  },
  components: { Page },
  props: ["title", "topImage", "roadmapItems"],
  data() {
    return {
      glowLineStyle: {},
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.resized);
    });
    this.resized();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resized);
  },
  methods: {
    resized() {
      setTimeout(() => {
        let lastPassed = 0;
        let idx = 0;
        for (const item of this.roadmapItems) {
          if (item.passed) {
            lastPassed = idx;
          }
          idx++;
        }
        let cardPos = this.$refs.titem[lastPassed].$el.offsetTop;
        let cardHeight = this.$refs.titem[lastPassed].$el.clientHeight;
        // This motherfucker (-8) needs to compensate for top margin
        let height = cardPos + cardHeight / 2 - 8;

        let left;
        switch (this.$vuetify.breakpoint.name) {
          case "sm":
          case "xs":
            left = "calc(48px - 1px)";
            break;
          default:
            left = "calc(50% - 1px)";
            break;
        }

        this.glowLineStyle = {
          height: `${height}px`,
          left: left,
        };
      }, 200);
    },
  },
  computed: {
    itemTitleFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          return "poppins-bold-white-32px";
        case "sm":
        case "xs":
          return "poppins-bold-white-20px";
      }
    },
    itemContentFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-normal-white-20px";
        case "md":
        case "sm":
        case "xs":
          return "poppins-normal-white-12px";
      }
    },
    iconWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "240";
        case "md":
        case "sm":
        case "xs":
          return "100";
      }
    },
    timelineOverlay() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "roadmap-timeline-overlay-desktop";
        case "md":
        case "sm":
        case "xs":
          return "roadmap-timeline-overlay-mobile";
      }
    }
  },
};
</script>

<style scoped>

.roadmap-card {
  border: 1px solid transparent !important;
  border-radius: 20px !important;
  background: 
    linear-gradient(to bottom, black, black), 
    linear-gradient(to bottom, #3B3165 , #CBBEFF, #3B3165); 
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  overflow: hidden;  
}

.roadmap-card-divider-passed {
  border-bottom: 1px solid #0FF !important;
}

.roadmap-card-divider-not-passed {
  border-bottom: 1px solid #FF4A3A !important;
}

.animated-not-passed {
  border: 1px solid #FF4A3A !important;
  animation-name: animated-glow-not-passed;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.animated-passed {
  border: 1px solid #0FF !important;
  animation-name: animated-glow-passed;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.v-timeline::before {
  width: 1px !important;
  background: white !important;
  top: 160px;
}

.roadmap-timeline-overlay-desktop {
  background-color: #00ffff;
  width: 3px;
  position: absolute;
  border-radius: 10px;
  top: 160px;
}

.roadmap-timeline-overlay-mobile {
  background-color: #00ffff;
  width: 3px;
  position: absolute;
  border-radius: 10px;
  top: 130px;
  margin-left: -1px;
}

.roadmap-timeline-item-text {
  line-height: 27px;
}

.v-timeline-item__divider {
  max-width: 56px !important;
}

@keyframes animated-glow-not-passed {
  0%   { box-shadow: 0px 0px 10px 0px #FF4A3A; }
  50%  { box-shadow: 0px 0px 20px 0px #FF4A3A; }
  100% { box-shadow: 0px 0px 10px 0px #FF4A3A; }
}

@keyframes animated-glow-passed {
  0%   { box-shadow: 0px 0px 10px 0px #0FF; }
  50%  { box-shadow: 0px 0px 20px 0px #0FF; }
  100% { box-shadow: 0px 0px 10px 0px #0FF; }
}
</style>
