<template>
  <Page :title="title" :titleCaption="titleShadow" class="aboutus-top-bg">
    <v-row justify="center" class="mt-md-10 mb-md-6" style="width: 100%">
      <v-col lg="2" cols="0"></v-col>
      <v-col
        lg="5"
        cols="11"
        class="d-flex flex-column justify-center pr-lg-16"
      >
        <div class="aboutus-top-content justified" :class="contentFont">
          {{ topContent[0] }}
        </div>
        <div class="aboutus-top-content justified mt-md-4" :class="contentFont">
          {{ topContent[1] }}
        </div>
        <div class="aboutus-top-content justified mt-md-4" :class="contentFont">
          {{ topContent[2] }}
        </div>
        <div class="aboutus-top-content justified mt-md-4" :class="contentFont">
          {{ topContent[3] }}
        </div>
      </v-col>
      <v-col lg="4" cols="11">
        <v-img
          :src="require('@/assets/img/agent/agent.png')"
          contain
          class="mr-lg-16"
        ></v-img>
      </v-col>
      <v-col lg="1" cols="0"></v-col>
    </v-row>
    <v-row class="aboutus-middle-content-bg py-10" justify="center">
      <v-col lg="8" cols="11">
        <div class="aboutus-top-content justified" :class="contentFont">
          {{ middleContent[0] }}
        </div>
        <div class="aboutus-top-content justified mt-md-4" :class="contentFont">
          {{ middleContent[1] }}
        </div>
        <div class="aboutus-top-content justified mt-md-4" :class="contentFont">
          {{ middleContent[2] }}
        </div>
      </v-col>
    </v-row>
    <v-row
      class="aboutus-icons-bg pt-4 pb-8"
      justify="center"
      style="width: 100vw"
    >
      <v-col lg="8" cols="11">
        <v-row justify="center">
          <v-col sm="3" cols="5" class="d-flex align-center flex-column px-6">
            <div :class="picFont" class="aboutus-pic-text py-4">
              {{ picContent[0] }}
            </div>
            <v-img
              src="@/assets/img/aboutus/Time@2x.png"
              lazy-src="@/assets/img/aboutus/Time.png"
              contain
            ></v-img>
          </v-col>
          <v-col sm="3" cols="5" class="d-flex align-center flex-column px-6">
            <div :class="picFont" class="aboutus-pic-text py-4">
              {{ picContent[1] }}
            </div>
            <v-img
              src="@/assets/img/aboutus/Rewards@2x.png"
              lazy-src="@/assets/img/aboutus/Rewards.png"
              contain
            ></v-img>
          </v-col>
          <v-col sm="3" cols="5" class="d-flex align-center flex-column px-6">
            <div :class="picFont" class="aboutus-pic-text py-4">
              {{ picContent[2] }}
            </div>
            <v-img
              src="@/assets/img/aboutus/Goals@2x.png"
              lazy-src="@/assets/img/aboutus/Goals.png"
              contain
            ></v-img>
          </v-col>
          <v-col sm="3" cols="5" class="d-flex align-center flex-column px-6">
            <div :class="picFont" class="aboutus-pic-text py-4">
              {{ picContent[3] }}
            </div>
            <v-img
              src="@/assets/img/aboutus/Data@2x.png"
              lazy-src="@/assets/img/aboutus/Data.png"
              contain
            ></v-img>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="aboutus-quote-bg">
      <v-col>
        <TextQuote :quote="quote.text" :author="quote.author" expanded  />
      </v-col>
    </v-row>
    <v-row class="aboutus-missionvision-bg py-10" justify="center">
      <v-img
        :lazySrc="missionVisionBgImgLazy"
        :src="missionVisionBgImg"
        class="aboutus-missionvision-wave"
        contain
      ></v-img>
      <v-col
        lg="9"
        cols="11"
        class="aboutus-missionvision-inner my-md-10"
      >
        <v-row justify="center" class="py-10">
          <v-col md="5" cols="11" class="d-flex flex-column">
            <div :class="titleFont" class="align-self-center align-self-md-start">{{ ourMission }}</div>
            <div :class="contentFont" class="justified mt-10">{{ timeworksIsOnAMi }}</div>
          </v-col>
          <v-col md="1" cols="0" />
          <v-col md="5" cols="11" class="d-flex flex-column">
            <div :class="titleFont" class="align-self-center align-self-md-start">{{ ourVision }}</div>
            <div :class="contentFont" class="justified mt-10">
              {{ weEnvisionTimework }}
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="aboutus-aboutus-bg py-10" justify="center">
      <WidgetWrapper class="about-us-parallax-bg" />
      <v-col lg="9" cols="11" class="aboutus-aboutus-inner pa-10 my-md-10">
        <v-row justify="center">
          <v-col cols="11" class="d-flex flex-column">
            <div
              :class="titleFont"
              class="align-self-center"
              style="color: #1e0144 !important"
            >
              {{ whoWeAre }}
            </div>
            <div
              :class="contentFont"
              style="color: #1e0144 !important"
              class="justified mt-10 mb-md-10"
            >
              {{ whoIsTheWeBehi }}
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      class="aboutus-team-bg"
      justify="center"
      style="position: relative; overflow: hidden"
    >
      <v-img
      :lazySrc="require('@/assets/img/team/team-bg.png')"
        :src="require('@/assets/img/team/team-bg@2x.png')"
        style="position: absolute; opacity: 0.5"
        width="100%"
        height="100%"
      ></v-img>
      <v-col lg="9" md="11" cols="12" class="py-md-12 py-8" style="z-index: 1">
        <div class="d-flex flex-column align-center mx-lg-n8">
          <div :class="titleFont">
            {{ teamTitle }}
          </div>
          <v-row justify="center" class="mt-md-4" style="width: 100%">
            <v-col
              lg="3"
              sm="4"
              cols="6"
              v-for="(item, index) in team"
              :key="index"
              class="mt-6"
            >
              <v-hover v-slot="{ hover }">
                <v-card
                  :elevation="hover ? 12 : 2"
                  :class="{ 'on-hover': hover }"
                  class="d-flex flex-column align-center mx-lg-2"
                  style="position: relative; border-radius: 0px; background: transparent;"
                >
                  <v-img :src="item.img" width="100%" contain></v-img>
                  <transition name="teampic">
                    <div
                      class="px-2 pb-4"
                      style="position: absolute; bottom: 0; width: 100%"
                      v-if="hover"
                    >
                      <div
                        class="team-card-bg mx-n2"
                        style="position: absolute; width: 100%; height: 100%"
                      ></div>
                      <div
                        :class="teamNameFont"
                        class="mt-6"
                        style="text-align: center; position: relative"
                      >
                        {{ item.name }}
                      </div>
                      <div
                        :class="teamJobFont"
                        class="mt-2"
                        style="text-align: center; position: relative"
                      >
                        {{ item.job }}
                      </div>
                      <a
                        :href="item.linkedin"
                        target="_blank"
                        rel="noopener noreferrer"
                        v-if="item.linkedin != null"
                      >
                        <v-img
                          class="mt-2 mt-md-4"
                          :src="
                            require('@/assets/img/socialmedia/linkedin.svg')
                          "
                          :max-width="linkedinSize"
                          style="
                            position: relative;
                            margin-left: auto;
                            margin-right: auto;
                          "
                        ></v-img>
                      </a>
                      <a
                        :href="item.wikipedia"
                        target="_blank"
                        rel="noopener noreferrer"
                        v-if="item.wikipedia != null"
                      >
                        <v-img
                          class="mt-2 mt-md-4"
                          :src="
                            require('@/assets/img/socialmedia/wikipedia.svg')
                          "
                          :max-width="linkedinSize"
                          style="
                            position: relative;
                            margin-left: auto;
                            margin-right: auto;
                          "
                        ></v-img>
                      </a>
                    </div>
                  </transition>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-row
      class="aboutus-advisors-bg"
      justify="center"
      style="position: relative; overflow: hidden"
    >
      <v-col lg="9" md="11" cols="12" class="py-md-12 py-8" style="z-index: 1">
        <div class="d-flex flex-column align-center mx-lg-n8">
          <div :class="titleFont">
            {{ advisorsTitle }}
          </div>
          <v-row justify="center" class="mt-md-4" style="width: 100%">
            <v-col
              lg="3"
              sm="4"
              cols="6"
              v-for="(item, index) in advisors"
              :key="index"
              class="mt-6"
            >
              <v-hover v-slot="{ hover }">
                <v-card
                  :elevation="hover ? 12 : 2"
                  :class="{ 'on-hover': hover }"
                  class="d-flex flex-column align-center mx-lg-2"
                  style="position: relative; border-radius: 0px; background: transparent;"
                >
                  <v-img :src="item.img" width="100%" contain></v-img>
                  <transition name="teampic">
                    <div
                      class="px-2 pb-4"
                      style="position: absolute; bottom: 0; width: 100%"
                      v-if="hover"
                    >
                      <div
                        class="team-card-bg mx-n2"
                        style="position: absolute; width: 100%; height: 100%"
                      ></div>
                      <div
                        :class="teamNameFont"
                        class="mt-6"
                        style="text-align: center; position: relative"
                      >
                        {{ item.name }}
                      </div>
                      <div
                        :class="teamJobFont"
                        class="mt-2"
                        style="text-align: center; position: relative"
                      >
                        {{ item.job }}
                      </div>
                      <div
                        :class="teamTitleFont"
                        style="text-align: center; position: relative"
                      >
                        {{ item.title }}
                      </div>
                      <a
                        :href="item.linkedin"
                        target="_blank"
                        rel="noopener noreferrer"
                        v-if="item.linkedin != null"
                      >
                        <v-img
                          class="mt-2 mt-md-4"
                          :src="
                            require('@/assets/img/socialmedia/linkedin.svg')
                          "
                          :max-width="linkedinSize"
                          style="
                            position: relative;
                            margin-left: auto;
                            margin-right: auto;
                          "
                        ></v-img>
                      </a>
                      <a
                        :href="item.wikipedia"
                        target="_blank"
                        rel="noopener noreferrer"
                        v-if="item.wikipedia != null"
                      >
                        <v-img
                          class="mt-2 mt-md-4"
                          :src="
                            require('@/assets/img/socialmedia/wikipedia.svg')
                          "
                          :max-width="linkedinSize"
                          style="
                            position: relative;
                            margin-left: auto;
                            margin-right: auto;
                          "
                        ></v-img>
                      </a>
                    </div>
                  </transition>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </Page>
</template>

<script>
import Page from "../Page.vue";
import TextQuote from "../TextQuote.vue";
import WidgetWrapper from "../WidgetWrapper.vue";

export default {
  name: "AboutUs",
  metaInfo: {
    title: "About us"
  },
  components: { Page, TextQuote, WidgetWrapper },
  props: [
    "titleShadow",
    "title",
    "topContent",
    "middleContent",
    "picContent",
    "ourMission",
    "timeworksIsOnAMi",
    "ourVision",
    "weEnvisionTimework",
    "whoIsTheWeBehi",
    "whoWeAre",
    "aboutUs",
    "teamTitle",
    "team",
    "advisorsTitle",
    "advisors",
    "quote",
    "missionVisionBgImg",
    "missionVisionBgImgLazy",
    "staking",
  ],
  computed: {
    contentFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-normal-white-18px";
        case "md":
        case "sm":
          if (!this.$isMobile()) {
            return "poppins-normal-white-16px";
          } else {
            return "poppins-normal-white-15px";
          }
        case "xs":
          return "poppins-normal-white-15px";
      }
    },
    picFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
          return "poppins-bold-50px";
        case "lg":
          return "poppins-bold-40px";
        case "md":
        case "sm":
          if (!this.$isMobile()) {
            return "poppins-bold-28px";
          } else {
            return "poppins-bold-24px";
          }
        case "xs":
          return "poppins-bold-24px";
      }
    },
    titleFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-bold-white-50px";
        case "md":
        case "sm":
          if (!this.$isMobile()) {
            return "poppins-bold-white-34px";
          } else {
            return "poppins-bold-white-30px";
          }
        case "xs":
          return "poppins-bold-white-25px";
      }
    },
    teamNameFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
          return "poppins-bold-white-24px";
        case "lg":
          return "poppins-bold-white-20px";
        case "md":
        case "sm":
          if (!this.$isMobile()) {
            return "poppins-bold-white-20px";
          } else {
            return "poppins-bold-white-14px";
          }
        case "xs":
          return "poppins-bold-white-14px";
      }
    },
    teamJobFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
          return "poppins-normal-white-16px";
        case "lg":
          return "poppins-normal-white-16px";
        case "md":
        case "sm":
          if (!this.$isMobile()) {
            return "poppins-normal-white-12px";
          } else {
            return "poppins-normal-white-9px";
          }
        case "xs":
          return "poppins-normal-white-9px";
      }
    },
    teamTitleFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
          return "poppins-bold-white-12px";
        case "lg":
          return "poppins-bold-white-12px";
        case "md":
        case "sm":
          if (!this.$isMobile()) {
            return "poppins-bold-white-10px";
          } else {
            return "poppins-bold-white-7px";
          }
        case "xs":
          return "poppins-bold-white-7px";
      }
    },
    linkedinSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return 30;
        case "md":
        case "sm":
          if (!this.$isMobile()) {
            return 24;
          } else {
            return 18;
          }
        case "xs":
          return 18;
      }
    },
  },
};
</script>

<style scoped>
.aboutus-top-bg {
  background: transparent linear-gradient(275deg, #18002e 0%, #310293 100%) 0%
    0% no-repeat padding-box;
}

.aboutus-top-content {
  text-align: left;
  line-height: 27px;
}

.aboutus-middle-content-bg {
  background: #310293 0% 0% no-repeat padding-box;
}

.aboutus-pic-text {
  color: #00ffff;
}

.aboutus-icons-bg {
  background: #18002e 0% 0% no-repeat padding-box;
  max-width: 1920px;
}

.aboutus-quote-bg {
  background: #310293 0% 0% no-repeat padding-box;
  width: 100vw;
}

.aboutus-missionvision-bg {
  background: #6248C4 0% 0% no-repeat padding-box;
  position: relative;
  overflow-y: hidden;
}

.aboutus-missionvision-inner {
  background: transparent linear-gradient(294deg, #18002e 0%, #310293 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 2px 4px #00000029;
  z-index: 1;
}

.aboutus-missionvision-wave {
  position: absolute;
  bottom: 0;
}

.about-us-parallax-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
}

.aboutus-aboutus-bg {
  position: relative;
  overflow: hidden;
}

.aboutus-aboutus-inner {
  background: #ffffff 0% 0% no-repeat padding-box;
  z-index: 1;
}

.aboutus-team-bg {
  background: linear-gradient(103.37deg, #310293 0%, #18002E 100%);
  width: 100vw;
  max-width: 1920px;
}

.aboutus-advisors-bg {
  background: #4A2DAB;
  width: 100vw;
  max-width: 1920px;
}

.teampic-enter-active,
.teampic-leave-active {
  transition: opacity 0.4s;
}

.teampic-enter,
.teampic-leave-to {
  opacity: 0;
}
.team-card-bg {
  background: #000000 0% 0% no-repeat padding-box;
  opacity: 0.75;
  filter: blur(25px);
}
.aboutus-staking-bg {
  width: 100vw;
  max-width: 1920px;
}

a:link { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: none; }
a:active { text-decoration: none; }

.justified {
  text-align: justify;
  text-justify: inter-word;
}
</style>
