var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Page',{attrs:{"title":_vm.title,"top-image":_vm.topImage}},[_c('v-row',{attrs:{"justify":"center","no-gutters":""}},[_c('v-col',[_c('v-timeline',{staticClass:"my-6 ml-n5 ml-md-0",attrs:{"dense":_vm.$vuetify.breakpoint.smAndDown,"reverse":_vm.$vuetify.breakpoint.mdAndUp}},[_vm._l((_vm.roadmapItems),function(item,index){return _c('v-timeline-item',{key:item.label,ref:"titem",refInFor:true,attrs:{"color":item.passed ? '#00FFFF' : '#FF5800',"small":"","fill-dot":""}},[_c('v-card',{staticClass:"roadmap-card pa-md-10 pa-5 ml-n5 ml-md-0",class:[
              item.animated ? (item.passed ? 'animated-passed' : 'animated-not-passed') : '',
              index == 0 ? 'mb-md-10' : 'my-md-10',
            ]},[_c('v-card-title',[_c('div',{class:_vm.itemTitleFont,style:({
                  color: item.passed ? '#00FFFF !important' : '#FF5800 !important',
                  wordBreak: 'normal',
                })},[_vm._v(" "+_vm._s(item.label)+" ")])]),_c('v-card-text',[_c('div',{staticClass:"my-3 my-md-6",class:item.passed ? 'roadmap-card-divider-passed' : 'roadmap-card-divider-not-passed'}),_c('div',{class:_vm.itemContentFont},[_vm._v(" "+_vm._s(item.content)+" ")])])],1)],1)}),_c('div',{class:_vm.timelineOverlay,style:(_vm.glowLineStyle)})],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }