<template>
  <div :class="background">
    <v-container class="page-container">
      <v-row :class="top" class="align-center" :style="{ 'background': `url(${topImage}) no-repeat center` }">
        <v-col>
          <h1 :class="heading">{{ title }}</h1>
        </v-col>
      </v-row>
      <v-row>
        <slot />
      </v-row>
    </v-container>
  </div>
</template>

<script>

export default {
  name: "DarkPage",
  props: ["title", "topImage"],
  computed: {
    background() {
        switch (this.$vuetify.breakpoint.name) {
            case "xl":
            case "lg":
            case "md":
                return "background-desktop";
            case "sm":
            case "xs":
                return "background-mobile";
        }
    },
    top() {
        switch (this.$vuetify.breakpoint.name) {
            case "xl":
            case "lg":
            case "md":
                return "top-desktop";
            case "sm":
            case "xs":
                return "top-mobile";
        }
    },
    heading() {
      switch (this.$vuetify.breakpoint.name) {
          case "xl":
          case "lg":
          case "md":
              return "heading heading-desktop poppins-bold-white-80px";
          case "sm":
          case "xs":
              return "heading heading-mobile poppins-bold-white-24px";
      }
    },
  },
};
</script>

<style scoped>
.page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1480px;
    margin: auto;
}

.top-desktop {
    background-size: cover;
    width: 1920px;
    height: 540px;
    padding-top: 140px;
    padding-bottom: 100px;
}

.top-mobile {
    background-size: cover !important;
    width: 300%;
    height: 200px;
    padding: 80px 0px;
    margin-bottom: -40px;
}

.background-desktop {
    background: linear-gradient(180deg, #310293 0%, #100032 5%, #000000 10%);
}

.background-mobile {
    background: linear-gradient(180deg, #310293 -0.5%, #100032 2.5%, #000000 7%);
}

.heading {
    text-align: center;
}

.heading-desktop {
    line-height: 96px;
}

.heading-mobile {
    line-height: normal;
}
</style>
